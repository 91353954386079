import '../../Admin.scss';
import '../../../../styles/_table.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import DeleteListItemModal from 'components/Admin/Modals/DeleteListItemModal/DeleteListItemModal';
import { Link } from 'react-router-dom';
import Paragraph from 'components/TextComps/Paragraph';
import { PartnerOrMaster } from 'types/partner';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import { ReactComponent as SearchIcon } from 'images/icons/Search.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  fullName: string;
};

export const PartnersList = observer(() => {
  useSetPageTitle({ title: 'Partners List', subtitle: '' });
  const ps = useStores().partnersStore;
  const pes = useStores().partnersEditorStore;
  const [searchValue, setSearchValue] = useState('');
  const [isModalOn, setIsModalOn] = useState(false);

  useEffect(() => {
    ps.getPartners();
  }, [ps]);

  useEffect(() => {
    if (typeof ps.partners[0] !== 'number' && 'fullName' in ps.partners[0]) {
      ps.filteredList = (ps.partners as PartnerOrMaster[]).filter((partner) =>
        partner.fullName.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  }, [ps, searchValue]);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      ps.filteredList.map((partner) => {
        return {
          id: partner.id,
          fullName: partner.fullName,
        };
      }) as RowsDisplay[],
    [ps.filteredList]
  );

  const deletePartner = useCallback(() => {
    pes.selectedPartner.id && pes.deletePartner(pes.selectedPartner.id);
    setIsModalOn(false);
  }, [pes, setIsModalOn]);

  return (
    <div className='admin-list-container admin-content-container'>
      <div className='admin-list-header'>
        <div className='admin-list-header-searchbar'>
          <div className='text-field'>
            <input
              className='search-input'
              placeholder={'Search'}
              name='search-for-partner'
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
            <SearchIcon />
          </div>
        </div>
        <div className='admin-list-header-button'>
          <Link to={`/Admin/${DefaultRoutes.PartnersEditor}`} className='styled-button primary link-button'>
            <PlusIcon />
            <span>New partner</span>
          </Link>
        </div>
      </div>

      <div className='tmu-table-wrapper'>
        <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplay.length ? (
              rowsDisplay.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell>{row.fullName.toLowerCase()}</TableCell>
                    <TableCell>
                      <div className='action-buttons'>
                        <Link to={`/Admin/${DefaultRoutes.PartnersEditor}/${row.id}`}>
                          <PencilIcon />
                        </Link>
                        <button
                          className={`row-button ${pes.isLoading ? 'disabled' : ''}`}
                          onClick={() => {
                            pes.selectedPartner.id = row.id;
                            setIsModalOn(true);
                          }}
                          disabled={pes.isLoading}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Paragraph>
                    <>No partners found</>
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DeleteListItemModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} deleteAction={deletePartner} itemName='partner' />
    </div>
  );
});

export default PartnersList;
