import { ParameterModel, VideoUrlModel } from 'types/trackmanParameters';

import LearningMaterialsLessonVideo from 'components/LearningMaterialsRoom/LearningMaterialsLesson/LearningMaterialsLessonVideo/LearningMaterialsLessonVideo';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlayButtonIcon } from '../../../images/icons/PlayButton.svg';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type Props = {
  visibleParameter: ParameterModel;
  visibleVideo: VideoUrlModel;
  setVisibleVideo: (video: VideoUrlModel) => void;
};

export const ParameterVideos = observer(({ visibleParameter, visibleVideo, setVisibleVideo }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='videos-info'>
      <LearningMaterialsLessonVideo title={visibleVideo.label} youTubeId={visibleVideo.videoUrl} />
      <h3>{t('Videos', { ns: 'trackmanParameters' })}</h3>
      <Paragraph>
        <>{t('WhatIsThisParameter', { ns: 'trackmanParameters', parameterTitle: visibleParameter.title })}</>
      </Paragraph>
      {visibleParameter.videoUrls.length > 1 ? (
        <div className='videos-list'>
          {visibleParameter.videoUrls.map((video: VideoUrlModel) => (
            <div key={video.videoUrl} className='video-item'>
              <div className='thumbnail-wrapper' onClick={() => setVisibleVideo(video)}>
                <img src={`https://img.youtube.com/vi/${video.videoUrl}/mqdefault.jpg`} alt={video.label} />
                <PlayButtonIcon />
              </div>
              <Paragraph>
                <>{video.label}</>
              </Paragraph>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
});

export default ParameterVideos;
