import { LearningMaterialModel, LearningMaterialTypeList, LearningMaterialsList } from 'types/admin/learningMaterials';
import { configure, makeAutoObservable } from 'mobx';

import { AdminImageType } from 'types/admin/media';
import { CalculatorKind } from 'types/learningMaterials';
import LearningMaterialService from '../../../services/Admin/Editors/LearningMaterialService';
import { NotificationsStore } from '../../NotificationsStore';
import { getCalcOptionsByType } from 'utilities/adminCalculatorOptions';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export const cleanLearningMaterial: LearningMaterialModel = {
  id: '',
  titles: [{ countryCode: 'en-US', text: '' }],
  descriptions: [{ countryCode: 'en-US', text: '' }],
  languages: ['en-US'],
  medias: [],
  kind: 'Text',
  youTubeKey: '',
  calculatorKind: 'CALC_NUM',
  calculatorOptions: getCalcOptionsByType('CALC_NUM' as CalculatorKind),
};
export class LearningMaterialStore {
  private learningMaterialService: LearningMaterialService;
  private ns: NotificationsStore;

  constructor(learningMaterialService: LearningMaterialService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.learningMaterialService = learningMaterialService;
    this.ns = notificationStore;
  }

  learningMaterialsList: LearningMaterialsList = [];
  mediaListData: AdminImageType[] = [];
  learningMaterial = { ...cleanLearningMaterial };
  selectedMaterialId: string = '';
  filteredList: LearningMaterialsList = [];
  learningMaterialTypes: LearningMaterialTypeList = [];

  isLoading: boolean = false;

  getLearningMaterials = async () => {
    try {
      this.isLoading = true;
      const response = await this.learningMaterialService.getLearningMaterials();
      this.learningMaterialsList = response;
      this.filteredList = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getLearningMaterial = async (id: string) => {
    try {
      this.isLoading = true;
      const response = await this.learningMaterialService.getLearningMaterial(id);
      this.learningMaterial = response;
      if (this.learningMaterial.medias === null) this.learningMaterial.medias = [];
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteLearningMaterialAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.learningMaterialService.deleteLearningMaterial(this.selectedMaterialId);
      this.getLearningMaterials();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveNewLearningMaterialAndUpdate = async () => {
    try {
      this.isLoading = true;
      delete this.learningMaterial.id;
      await this.learningMaterialService.saveNewLearningMaterial(this.learningMaterial);
      this.ns.addToast('success', 'New lesson has been saved successfully', 'success');
      this.learningMaterial = structuredClone(cleanLearningMaterial);
      this.getLearningMaterials();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveEditedLearningMaterialAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.learningMaterialService.saveEditedLearningMaterial(this.learningMaterial);
      this.ns.addToast('success', 'Existing lesson has been saved successfully', 'success');
      this.getLearningMaterials();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getAllLearningMaterialTypes = async () => {
    try {
      this.isLoading = true;
      const response = await this.learningMaterialService.getAllLearningMaterialTypes();
      this.learningMaterialTypes = response;
      this.learningMaterialTypes.unshift({ displayName: 'All', Id: '', keyword: '', type: 'Unknown' });
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default LearningMaterialStore;
