import DOMPurify from 'dompurify';
import { ParameterModel } from 'types/trackmanParameters';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type Props = {
  visibleParameter: ParameterModel;
};

export const ArticlesInfo = observer(({ visibleParameter }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='articles-info'>
      <h3>{t('AdditionalArticlesOnTheParameter', { ns: 'trackmanParameters' })}</h3>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(visibleParameter.articlesInfo) }} />
    </div>
  );
});

export default ArticlesInfo;
