import '../SideMenu.scss';

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DefaultRoutes } from 'Routes';
import { observer } from 'mobx-react-lite';

const adminSubmenuData = [
  {
    label: 'Courses',
    route: DefaultRoutes.CourseList,
  },
  {
    label: 'Lessons',
    route: DefaultRoutes.LessonList,
  },
  {
    label: 'Study Courses',
    route: DefaultRoutes.StudyCoursesList,
  },
  {
    label: 'Study Lessons',
    route: DefaultRoutes.StudyLessonsList,
  },
  {
    label: 'Learning Materials',
    route: DefaultRoutes.LearningMaterialsList,
  },
  {
    label: 'Trackman Parameters',
    route: DefaultRoutes.TrackmanParametersList,
  },
  {
    label: 'User',
    route: DefaultRoutes.User,
  },
  { label: 'Translation', route: DefaultRoutes.Translation },
  {
    label: 'Organization List',
    route: DefaultRoutes.OrganizationsList,
  },
  {
    label: 'Test Invitations',
    route: DefaultRoutes.TestInvitations,
  },
  {
    label: 'Partners List',
    route: DefaultRoutes.PartnersList,
  },
  {
    label: 'Masters List',
    route: DefaultRoutes.MastersList,
  },
];

export const AdminSubmenu = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState('');

  useEffect(() => {
    setIsActive(location.pathname);
  }, [location.pathname]);

  return (
    <ul className='admin-submenu-container'>
      {adminSubmenuData.map((item, index) => (
        <li
          key={index}
          className={`menu-item submenu-item ${isActive.includes(DefaultRoutes.Admin + '/' + item.route) ? 'active' : ''}`}
          onClick={() => navigate(DefaultRoutes.Admin + '/' + item.route)}
        >
          {item.label}
        </li>
      ))}
    </ul>
  );
});

export default AdminSubmenu;
